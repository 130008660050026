import _defineProperty from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import * as React from 'react';
import * as hoistNonReactStatics_ from 'hoist-non-react-statics'; // Since rollup cannot deal with namespace being a function,
// this is to interop with TypeScript since `invariant`
// does not export a default
// https://github.com/rollup/rollup/issues/1267

var hoistNonReactStatics = hoistNonReactStatics_.default || hoistNonReactStatics_;
import { invariantIntlContext } from '../utils';

function getDisplayName(Component) {
  return Component.displayName || Component.name || 'Component';
} // TODO: We should provide initial value here


var IntlContext = React.createContext(null);
var IntlConsumer = IntlContext.Consumer,
    IntlProvider = IntlContext.Provider;
export var Provider = IntlProvider;
export var Context = IntlContext;
export default function injectIntl(WrappedComponent, options) {
  var _ref = options || {},
      _ref$intlPropName = _ref.intlPropName,
      intlPropName = _ref$intlPropName === void 0 ? 'intl' : _ref$intlPropName,
      _ref$forwardRef = _ref.forwardRef,
      forwardRef = _ref$forwardRef === void 0 ? false : _ref$forwardRef,
      _ref$enforceContext = _ref.enforceContext,
      enforceContext = _ref$enforceContext === void 0 ? true : _ref$enforceContext;

  var WithIntl = function WithIntl(props) {
    return React.createElement(IntlConsumer, null, function (intl) {
      if (enforceContext) {
        invariantIntlContext(intl);
      }

      return React.createElement(WrappedComponent, Object.assign({}, props, _defineProperty({}, intlPropName, intl), {
        ref: forwardRef ? props.forwardedRef : null
      }));
    });
  };

  WithIntl.displayName = "injectIntl(".concat(getDisplayName(WrappedComponent), ")");
  WithIntl.WrappedComponent = WrappedComponent;

  if (forwardRef) {
    return hoistNonReactStatics(React.forwardRef(function (props, ref) {
      return React.createElement(WithIntl, Object.assign({}, props, {
        forwardedRef: ref
      }));
    }), WrappedComponent);
  }

  return hoistNonReactStatics(WithIntl, WrappedComponent);
}