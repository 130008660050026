'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useDebouncedCallback;

var _react = require('react');

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  } else {
    return Array.from(arr);
  }
}

function useDebouncedCallback(callback, delay, deps) {
  var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var maxWait = options.maxWait;
  var maxWaitHandler = (0, _react.useRef)(null);
  var maxWaitArgs = (0, _react.useRef)([]);
  var functionTimeoutHandler = (0, _react.useRef)(null);
  var isComponentUnmounted = (0, _react.useRef)(false);
  var debouncedFunction = (0, _react.useCallback)(callback, deps);
  var cancelDebouncedCallback = (0, _react.useCallback)(function () {
    clearTimeout(functionTimeoutHandler.current);
    clearTimeout(maxWaitHandler.current);
    maxWaitHandler.current = null;
    maxWaitArgs.current = [];
    functionTimeoutHandler.current = null;
  }, [functionTimeoutHandler.current, maxWaitHandler.current]);
  (0, _react.useEffect)(function () {
    return function () {
      // we use flag, as we allow to call callPending outside the hook
      isComponentUnmounted.current = true;
    };
  }, []);
  var debouncedCallback = (0, _react.useCallback)(function () {
    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    maxWaitArgs.current = args;
    clearTimeout(functionTimeoutHandler.current);
    functionTimeoutHandler.current = setTimeout(function () {
      if (!isComponentUnmounted.current) {
        debouncedFunction.apply(undefined, args);
      }

      cancelDebouncedCallback();
    }, delay);

    if (maxWait && !maxWaitHandler.current) {
      maxWaitHandler.current = setTimeout(function () {
        if (!isComponentUnmounted.current) {
          debouncedFunction.apply(undefined, _toConsumableArray(maxWaitArgs.current));
        }

        cancelDebouncedCallback();
      }, maxWait);
    }
  }, [debouncedFunction, maxWait, delay]);

  var callPending = function callPending() {
    // Call pending callback only if we have anything in our queue
    if (!functionTimeoutHandler.current) {
      return;
    }

    debouncedFunction.apply(undefined, _toConsumableArray(maxWaitArgs.current));
    cancelDebouncedCallback();
  }; // For the moment, we use 3 args array so that we save backward compatibility


  return [debouncedCallback, cancelDebouncedCallback, callPending];
}