/**
* upath http://github.com/anodynos/upath/
*
* A proxy to `path`, replacing `\` with `/` for all results & new methods to normalize & join keeping leading `./` and add, change, default, trim file extensions.
* Version 1.1.0 - Compiled on 2018-05-15 22:18:40
* Repository git://github.com/anodynos/upath
* Copyright(c) 2018 Angelos Pikoulas <agelos.pikoulas@gmail.com>
* License MIT
*/
// Generated by uRequire v0.7.0-beta.33 target: 'lib' template: 'nodejs'
var VERSION = '1.1.0'; // injected by urequire-rc-inject-version

var extraFn,
    extraFunctions,
    isFunction,
    isString,
    isValidExt,
    name,
    path,
    propName,
    propValue,
    toUnix,
    upath,
    slice = [].slice,
    indexOf = [].indexOf || function (item) {
  for (var i = 0, l = this.length; i < l; i++) {
    if (i in this && this[i] === item) return i;
  }

  return -1;
},
    hasProp = {}.hasOwnProperty;

path = require("path");

isFunction = function isFunction(val) {
  return val instanceof Function;
};

isString = function isString(val) {
  return typeof val === "string" || !!val && typeof val === "object" && Object.prototype.toString.call(val) === "[object String]";
};

upath = exports;
upath.VERSION = typeof VERSION !== "undefined" && VERSION !== null ? VERSION : "NO-VERSION";

toUnix = function toUnix(p) {
  var double;
  p = p.replace(/\\/g, "/");
  double = /\/\//;

  while (p.match(double)) {
    p = p.replace(double, "/");
  }

  return p;
};

for (propName in path) {
  propValue = path[propName];

  if (isFunction(propValue)) {
    upath[propName] = function (propName) {
      return function () {
        var args, result;
        args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
        args = args.map(function (p) {
          if (isString(p)) {
            return toUnix(p);
          } else {
            return p;
          }
        });
        result = path[propName].apply(path, args);

        if (isString(result)) {
          return toUnix(result);
        } else {
          return result;
        }
      };
    }(propName);
  } else {
    upath[propName] = propValue;
  }
}

upath.sep = "/";
extraFunctions = {
  toUnix: toUnix,
  normalizeSafe: function normalizeSafe(p) {
    p = toUnix(p);

    if (p.startsWith("./")) {
      if (p.startsWith("./..") || p === "./") {
        return upath.normalize(p);
      } else {
        return "./" + upath.normalize(p);
      }
    } else {
      return upath.normalize(p);
    }
  },
  normalizeTrim: function normalizeTrim(p) {
    p = upath.normalizeSafe(p);

    if (p.endsWith("/")) {
      return p.slice(0, +(p.length - 2) + 1 || 9000000000);
    } else {
      return p;
    }
  },
  joinSafe: function joinSafe() {
    var p, result;
    p = 1 <= arguments.length ? slice.call(arguments, 0) : [];
    result = upath.join.apply(null, p);

    if (p[0].startsWith("./") && !result.startsWith("./")) {
      result = "./" + result;
    }

    return result;
  },
  addExt: function addExt(file, ext) {
    if (!ext) {
      return file;
    } else {
      if (ext[0] !== ".") {
        ext = "." + ext;
      }

      return file + (file.endsWith(ext) ? "" : ext);
    }
  },
  trimExt: function trimExt(filename, ignoreExts, maxSize) {
    var oldExt;

    if (maxSize == null) {
      maxSize = 7;
    }

    oldExt = upath.extname(filename);

    if (isValidExt(oldExt, ignoreExts, maxSize)) {
      return filename.slice(0, +(filename.length - oldExt.length - 1) + 1 || 9000000000);
    } else {
      return filename;
    }
  },
  removeExt: function removeExt(filename, ext) {
    if (!ext) {
      return filename;
    } else {
      ext = ext[0] === "." ? ext : "." + ext;

      if (upath.extname(filename) === ext) {
        return upath.trimExt(filename);
      } else {
        return filename;
      }
    }
  },
  changeExt: function changeExt(filename, ext, ignoreExts, maxSize) {
    if (maxSize == null) {
      maxSize = 7;
    }

    return upath.trimExt(filename, ignoreExts, maxSize) + (!ext ? "" : ext[0] === "." ? ext : "." + ext);
  },
  defaultExt: function defaultExt(filename, ext, ignoreExts, maxSize) {
    var oldExt;

    if (maxSize == null) {
      maxSize = 7;
    }

    oldExt = upath.extname(filename);

    if (isValidExt(oldExt, ignoreExts, maxSize)) {
      return filename;
    } else {
      return upath.addExt(filename, ext);
    }
  }
};

isValidExt = function isValidExt(ext, ignoreExts, maxSize) {
  if (ignoreExts == null) {
    ignoreExts = [];
  }

  return ext && ext.length <= maxSize && indexOf.call(ignoreExts.map(function (e) {
    return (e && e[0] !== "." ? "." : "") + e;
  }), ext) < 0;
};

for (name in extraFunctions) {
  if (!hasProp.call(extraFunctions, name)) continue;
  extraFn = extraFunctions[name];

  if (upath[name] !== void 0) {
    throw new Error("path." + name + " already exists.");
  } else {
    upath[name] = extraFn;
  }
}

;