"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var console = global.console;
/* tslint:disable: no-console */

exports.logger = {
  debug: function debug(message) {
    var rest = [];

    for (var _i = 1; _i < arguments.length; _i++) {
      rest[_i - 1] = arguments[_i];
    }

    return console.debug.apply(console, [message].concat(rest));
  },
  log: function log(message) {
    var rest = [];

    for (var _i = 1; _i < arguments.length; _i++) {
      rest[_i - 1] = arguments[_i];
    }

    return console.log.apply(console, [message].concat(rest));
  },
  info: function info(message) {
    var rest = [];

    for (var _i = 1; _i < arguments.length; _i++) {
      rest[_i - 1] = arguments[_i];
    }

    return console.info.apply(console, [message].concat(rest));
  },
  warn: function warn(message) {
    var rest = [];

    for (var _i = 1; _i < arguments.length; _i++) {
      rest[_i - 1] = arguments[_i];
    }

    return console.warn.apply(console, [message].concat(rest));
  },
  error: function error(message) {
    var rest = [];

    for (var _i = 1; _i < arguments.length; _i++) {
      rest[_i - 1] = arguments[_i];
    }

    return console.error.apply(console, [message].concat(rest));
  }
};