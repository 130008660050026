'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useDebouncedCallback = exports.useDebounce = undefined;

var _cache = require('./cache');

var _cache2 = _interopRequireDefault(_cache);

var _callback = require('./callback');

var _callback2 = _interopRequireDefault(_callback);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

exports.useDebounce = _cache2.default;
exports.useDebouncedCallback = _callback2.default;