import _toConsumableArray from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/toConsumableArray";

/*
 * Copyright 2015, Yahoo Inc.
 * Copyrights licensed under the New BSD License.
 * See the accompanying LICENSE file for terms.
 */
import * as React from 'react';
import { invariant } from '@formatjs/intl-utils';
import { createError, escape } from '../utils';
import IntlMessageFormat from 'intl-messageformat';

function setTimeZoneInOptions(opts, timeZone) {
  return Object.keys(opts).reduce(function (all, k) {
    all[k] = Object.assign({
      timeZone: timeZone
    }, opts[k]);
    return all;
  }, {});
}

function deepMergeOptions(opts1, opts2) {
  var keys = Object.keys(Object.assign(Object.assign({}, opts1), opts2));
  return keys.reduce(function (all, k) {
    all[k] = Object.assign(Object.assign({}, opts1[k] || {}), opts2[k] || {});
    return all;
  }, {});
}

function deepMergeFormatsAndSetTimeZone(f1, timeZone) {
  if (!timeZone) {
    return f1;
  }

  var mfFormats = IntlMessageFormat.formats;
  return Object.assign(Object.assign(Object.assign({}, mfFormats), f1), {
    date: deepMergeOptions(setTimeZoneInOptions(mfFormats.date, timeZone), setTimeZoneInOptions(f1.date || {}, timeZone)),
    time: deepMergeOptions(setTimeZoneInOptions(mfFormats.time, timeZone), setTimeZoneInOptions(f1.time || {}, timeZone))
  });
}

export var prepareIntlMessageFormatHtmlOutput = function prepareIntlMessageFormatHtmlOutput(chunks) {
  return React.createElement.apply(React, [React.Fragment, null].concat(_toConsumableArray(chunks)));
};
export function formatMessage(_ref, state) {
  var locale = _ref.locale,
      formats = _ref.formats,
      messages = _ref.messages,
      defaultLocale = _ref.defaultLocale,
      defaultFormats = _ref.defaultFormats,
      onError = _ref.onError,
      timeZone = _ref.timeZone;
  var messageDescriptor = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
    id: ''
  };
  var values = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var id = messageDescriptor.id,
      defaultMessage = messageDescriptor.defaultMessage; // `id` is a required field of a Message Descriptor.

  invariant(!!id, '[React Intl] An `id` must be provided to format a message.');
  var message = messages && messages[String(id)];
  formats = deepMergeFormatsAndSetTimeZone(formats, timeZone);
  defaultFormats = deepMergeFormatsAndSetTimeZone(defaultFormats, timeZone);
  var formattedMessageParts = [];

  if (message) {
    try {
      var formatter = state.getMessageFormat(message, locale, formats, {
        formatters: state
      });
      formattedMessageParts = formatter.formatHTMLMessage(values);
    } catch (e) {
      onError(createError("Error formatting message: \"".concat(id, "\" for locale: \"").concat(locale, "\"") + (defaultMessage ? ', using default message as fallback.' : ''), e));
    }
  } else {
    // This prevents warnings from littering the console in development
    // when no `messages` are passed into the <IntlProvider> for the
    // default locale, and a default message is in the source.
    if (!defaultMessage || locale && locale.toLowerCase() !== defaultLocale.toLowerCase()) {
      onError(createError("Missing message: \"".concat(id, "\" for locale: \"").concat(locale, "\"") + (defaultMessage ? ', using default message as fallback.' : '')));
    }
  }

  if (!formattedMessageParts.length && defaultMessage) {
    try {
      var _formatter = state.getMessageFormat(defaultMessage, defaultLocale, defaultFormats);

      formattedMessageParts = _formatter.formatHTMLMessage(values);
    } catch (e) {
      onError(createError("Error formatting the default message for: \"".concat(id, "\""), e));
    }
  }

  if (!formattedMessageParts.length) {
    onError(createError("Cannot format message: \"".concat(id, "\", ") + "using message ".concat(message || defaultMessage ? 'source' : 'id', " as fallback.")));

    if (typeof message === 'string') {
      return message || defaultMessage || String(id);
    }

    return defaultMessage || String(id);
  }

  if (formattedMessageParts.length === 1 && typeof formattedMessageParts[0] === 'string') {
    return formattedMessageParts[0] || defaultMessage || String(id);
  }

  return prepareIntlMessageFormatHtmlOutput(formattedMessageParts);
}
export function formatHTMLMessage(config, state) {
  var messageDescriptor = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
    id: ''
  };
  var rawValues = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  // Process all the values before they are used when formatting the ICU
  // Message string. Since the formatted message might be injected via
  // `innerHTML`, all String-based values need to be HTML-escaped.
  var escapedValues = Object.keys(rawValues).reduce(function (escaped, name) {
    var value = rawValues[name];
    escaped[name] = typeof value === 'string' ? escape(value) : value;
    return escaped;
  }, {});
  return formatMessage(config, state, messageDescriptor, escapedValues);
}