'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray = function () {
  function sliceIterator(arr, i) {
    var _arr = [];
    var _n = true;
    var _d = false;
    var _e = undefined;

    try {
      for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
        _arr.push(_s.value);

        if (i && _arr.length === i) break;
      }
    } catch (err) {
      _d = true;
      _e = err;
    } finally {
      try {
        if (!_n && _i["return"]) _i["return"]();
      } finally {
        if (_d) throw _e;
      }
    }

    return _arr;
  }

  return function (arr, i) {
    if (Array.isArray(arr)) {
      return arr;
    } else if (Symbol.iterator in Object(arr)) {
      return sliceIterator(arr, i);
    } else {
      throw new TypeError("Invalid attempt to destructure non-iterable instance");
    }
  };
}();

exports.default = useDebounce;

var _react = require('react');

var _callback = require('./callback');

var _callback2 = _interopRequireDefault(_callback);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function useDebounce(value, delay) {
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

  var _useState = (0, _react.useState)(value),
      _useState2 = _slicedToArray(_useState, 2),
      debouncedValue = _useState2[0],
      setDebouncedValue = _useState2[1];

  var _useDebouncedCallback = (0, _callback2.default)(function (value) {
    return setDebouncedValue(value);
  }, delay, [value], options),
      _useDebouncedCallback2 = _slicedToArray(_useDebouncedCallback, 2),
      debouncedCallback = _useDebouncedCallback2[0],
      cancel = _useDebouncedCallback2[1];

  (0, _react.useEffect)(function () {
    if (debouncedValue !== value) {
      debouncedCallback(value);
    }
  });
  return [debouncedValue, cancel];
}