"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var events;

(function (events) {
  events["CHANNEL_CREATED"] = "channelCreated";
  events["GET_CURRENT_STORY"] = "getCurrentStory";
  events["SET_CURRENT_STORY"] = "setCurrentStory";
  events["GET_STORIES"] = "getStories";
  events["SET_STORIES"] = "setStories";
  events["STORIES_CONFIGURED"] = "storiesConfigured";
  events["SELECT_STORY"] = "selectStory";
  events["PREVIEW_KEYDOWN"] = "previewKeydown";
  events["STORY_ADDED"] = "storyAdded";
  events["STORY_CHANGED"] = "storyChanged";
  events["STORY_UNCHANGED"] = "storyUnchanged";
  events["FORCE_RE_RENDER"] = "forceReRender";
  events["REGISTER_SUBSCRIPTION"] = "registerSubscription";
  events["STORY_INIT"] = "storyInit";
  events["STORY_RENDER"] = "storyRender";
  events["STORY_RENDERED"] = "storyRendered";
  events["STORY_MISSING"] = "storyMissing";
  events["STORY_ERRORED"] = "storyErrored";
  events["STORY_THREW_EXCEPTION"] = "storyThrewException";
})(events || (events = {})); // Enables: `import Events from ...`


exports.default = events; // Enables: `import * as Events from ...` or `import { CHANNEL_CREATED } as Events from ...`
// This is the preferred method

exports.CHANNEL_CREATED = events.CHANNEL_CREATED;
exports.GET_CURRENT_STORY = events.GET_CURRENT_STORY;
exports.SET_CURRENT_STORY = events.SET_CURRENT_STORY;
exports.GET_STORIES = events.GET_STORIES;
exports.SET_STORIES = events.SET_STORIES;
exports.STORIES_CONFIGURED = events.STORIES_CONFIGURED;
exports.SELECT_STORY = events.SELECT_STORY;
exports.PREVIEW_KEYDOWN = events.PREVIEW_KEYDOWN;
exports.FORCE_RE_RENDER = events.FORCE_RE_RENDER;
exports.REGISTER_SUBSCRIPTION = events.REGISTER_SUBSCRIPTION;
exports.STORY_INIT = events.STORY_INIT;
exports.STORY_ADDED = events.STORY_ADDED;
exports.STORY_RENDER = events.STORY_RENDER;
exports.STORY_RENDERED = events.STORY_RENDERED;
exports.STORY_MISSING = events.STORY_MISSING;
exports.STORY_ERRORED = events.STORY_ERRORED;
exports.STORY_CHANGED = events.STORY_CHANGED;
exports.STORY_THREW_EXCEPTION = events.STORY_THREW_EXCEPTION;